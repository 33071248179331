var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shenpi"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v("审批管理")]),_c('c-button',{attrs:{"icon":"xinjian"},on:{"click":function($event){_vm.dataTypeFun();
        _vm.gotolink();}}},[_vm._v(" 新建表单 ")]),_c('c-button',{on:{"click":function($event){_vm.title = '新增分组';
        _vm.listname = '';
        _vm.dialogFormVisible = true;}}},[_vm._v(" 新建分组 ")])],1),_vm._l((_vm.listData),function(item,index){return _c('ul',{key:index},[_c('li',{on:{"click":function($event){_vm.listIndex = index}}},[_c('p',[_vm._v(" "+_vm._s(item.flow_group_name)+" ")]),_c('p',[_vm._v("可见范围")]),_c('span',[_c('a',{directives:[{name:"show",rawName:"v-show",value:(index !== 0),expression:"index !== 0"}],attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.move(_vm.listData, index, index - 1)}}},[_vm._v("上移")]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(index !== _vm.listData.length - 1),expression:"index !== listData.length - 1"}],attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.move(_vm.listData, index, index + 1)}}},[_vm._v("下移")]),(item.flow_group_name != '已停用')?_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.deletel(item, index)}}},[_vm._v("删除该组")]):_vm._e(),(item.flow_group_name != '已停用')?_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.BJmessage = item;
            _vm.title = '编辑分组';
            _vm.dialogFormVisible = true;
            _vm.listname = item.flow_group_name;}}},[_vm._v("编辑")]):_vm._e()]),_c('p',[_c('c-icon',{class:{ tram: _vm.listIndex === index },attrs:{"name":"zk","fill":"#fff"}})],1)]),_c('div',{staticClass:"content",class:{ active: _vm.listIndex === index }},_vm._l((item.children),function(items,indexs){return _c('li',{key:indexs},[_c('c-button',{staticClass:"firstButton",attrs:{"icon":items.icon}},[_vm._v(_vm._s(items.flow_name))]),_c('p',[_vm._v(_vm._s(items.fqr_disp))]),_c('span',[_c('a',{directives:[{name:"show",rawName:"v-show",value:(indexs !== 0),expression:"indexs !== 0"}],attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.moveitem(item.children, indexs, indexs - 1)}}},[_vm._v("上移")]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(indexs !== item.children.length - 1),expression:"indexs !== item.children.length - 1"}],attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.moveitem(item.children, indexs, indexs + 1)}}},[_vm._v("下移")]),_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.start(item, items)}}},[_vm._v(_vm._s(_vm._f("capitalize")(items.flow_group_id)))]),(items.flow_group_id !== 'stop')?_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.movestop(items)}}},[_vm._v("停用")]):_vm._e(),_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.gotolink(items);
              _vm.editFun(items);
              _vm.xinjianTypeBtn = 'baocun';}}},[_vm._v("编辑")])])],1)}),0)])}),_c('el-dialog',{attrs:{"title":"新增分组","visible":_vm.dialogFormVisible,"modal":true,"custom-class":"dialog1"},on:{"update:visible":function($event){_vm.dialogFormVisible=$event}}},[_c('el-input',{attrs:{"placeholder":"请输入分组名称"},model:{value:(_vm.listname),callback:function ($$v) {_vm.listname=$$v},expression:"listname"}}),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogFormVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.dialogFormVisible = false;
          _vm.savepush();}}},[_vm._v("确 定")])],1)],1),_c('el-dialog',{attrs:{"title":("将   \"" + (_vm.YDdata.title) + "\"   移动到"),"visible":_vm.dialogYD,"modal":true,"custom-class":"dialog1"},on:{"update:visible":function($event){_vm.dialogYD=$event}}},[_c('div',{staticClass:"dialog"},[_c('ul',_vm._l((_vm.listData1),function(item,index){return _c('li',{key:index,class:{ active: _vm.activeindex === item.flow_group_id },on:{"click":function($event){_vm.activeindex = item.flow_group_id}}},[_c('p',[_vm._v(_vm._s(item.flow_group_name))]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(item.flow_group_name === _vm.YDdata.name),expression:"item.flow_group_name === YDdata.name"}]},[_vm._v("当前所在组")])])}),0)]),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogYD = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.moveList()}}},[_vm._v("确 定")])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }