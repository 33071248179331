<template>
  <div class="shenpi">
    <div class="title">
      <p>审批管理</p>
      <c-button
        @click="
          dataTypeFun();
          gotolink();
        "
        icon="xinjian"
      >
        新建表单
      </c-button>
      <c-button
        @click="
          title = '新增分组';
          listname = '';
          dialogFormVisible = true;
        "
      >
        新建分组
      </c-button>
    </div>
    <ul v-for="(item, index) in listData" :key="index">
      <li @click="listIndex = index">
        <p>

          {{ item.flow_group_name }}
        </p>
        <p>可见范围</p>
        <span>
          <a
            v-show="index !== 0"
            @click="move(listData, index, index - 1)"
            href="javascript:;"
            >上移</a
          >
          <a
            v-show="index !== listData.length - 1"
            href="javascript:;"
            @click="move(listData, index, index + 1)"
            >下移</a
          >
          <a
            v-if="item.flow_group_name != '已停用'"
            href="javascript:;"
            @click="deletel(item, index)"
            >删除该组</a
          >
          <a
            href="javascript:;"
            v-if="item.flow_group_name != '已停用'"
            @click="
              BJmessage = item;
              title = '编辑分组';
              dialogFormVisible = true;
              listname = item.flow_group_name;
            "
            >编辑</a
          >
        </span>
        <p>
           <c-icon name="zk" fill='#fff' :class="{ tram: listIndex === index }"></c-icon>
        </p>
      </li>
      <div class="content" :class="{ active: listIndex === index }">
        <li v-for="(items, indexs) in item.children" :key="indexs">
          <c-button :icon="items.icon" class="firstButton">{{
            items.flow_name
          }}</c-button>
          <p>{{ items.fqr_disp }}</p>
          <span>
            <a
              href="javascript:;"
              v-show="indexs !== 0"
              @click="moveitem(item.children, indexs, indexs - 1)"
              >上移</a
            >
            <a
              href="javascript:;"
              v-show="indexs !== item.children.length - 1"
              @click="moveitem(item.children, indexs, indexs + 1)"
              >下移</a
            >
            <a href="javascript:;" @click="start(item, items)">{{
              items.flow_group_id | capitalize
            }}</a>
            <a
              href="javascript:;"
              v-if="items.flow_group_id !== 'stop'"
              @click="movestop(items)"
              >停用</a
            >
            <a
              href="javascript:;"
              @click="
                gotolink(items);
                editFun(items);
                xinjianTypeBtn = 'baocun';
              "
              >编辑</a
            >
          </span>
        </li>
      </div>
    </ul>
    <el-dialog
      title="新增分组"
      :visible.sync="dialogFormVisible"
      :modal="true"
      custom-class="dialog1"
    >
      <el-input placeholder="请输入分组名称" v-model="listname"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="
            dialogFormVisible = false;
            savepush();
          "
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :title="`将   &quot;${YDdata.title}&quot;   移动到`"
      :visible.sync="dialogYD"
      :modal="true"
      custom-class="dialog1"
    >
      <div class="dialog">
        <ul>
          <li
            v-for="(item, index) in listData1"
            :key="index"
            :class="{ active: activeindex === item.flow_group_id }"
            @click="activeindex = item.flow_group_id"
          >
            <p>{{ item.flow_group_name }}</p>
            <p v-show="item.flow_group_name === YDdata.name">当前所在组</p>
          </li>
        </ul>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogYD = false">取 消</el-button>
        <el-button type="primary" @click="moveList()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  query_flow_group_info_list,
  insert_flow_group_info,
  delete_flow_group_info,
  update_flow_group_info,
  update_group_of_flow_form,
  query_cust_form_detail,
  query_manager_of_form,
  query_flow_info_all,
  update_flow_group_sort,
  form_active_stop,
  update_flow_info_sort,
  insert_flow_info,
} from "@api/shenpi";

export default {
  name: "Spgl",
  data() {
    return {
      suijishu: "",
      xinjianTypeBtn: "",
      title: "",
      value1: "1",
      BJmessage: {},
      dialogFormVisible: false,
      listname: "",
      dialogYD: false,
      YDdata: {
        title: "",
        name: "",
        flow_id: "",
        orig_group_id: "",
      },
      listIndex: 0,
      listData: [],
      listData1: [],
      activeindex: null,
      beifenData: {},
    };
  },
  filters: {
    capitalize(val) {
      if (val === "stop") {
        return "启用";
      } else {
        return "移动到";
      }
    },
  },
  computed: {
    ent_id() {
      return this.$store.state.ent_id;
    },
    user_id() {
      return this.$store.state.user_id;
    },
  },
  created() {
    this.getlist();
    // console.log(this.$store.state.ent_id)

    // this.ent_id = this.$store.state.ent_id;

  },
  mounted() {
    window.Vue = this;
  },
  methods: {
    formTypeFun(params) {
      // 1自定义 2公共
      return this.$store.state.rndid.indexOf(params) > -1
        ? sessionStorage.setItem("formTypeBtn", "2")
        : sessionStorage.setItem("formTypeBtn", "1");
    },
    dataTypeFun() {
      this.xinjianTypeBtn = "xinjian";
      localStorage.setItem("deitData", "");
    },
    editFun(data) {
      localStorage.setItem("deitData", data.flow_name);
      if (this.$store.state.rndid.indexOf(data.flow_id) == -1) {
        query_cust_form_detail({
          data: {
            ent_id: this.ent_id,
            flow_id: data.flow_id,
          },
        }).then((res) => {
          if (res.code == 200) {
            const date = JSON.parse(JSON.stringify(res.body.data));
            query_manager_of_form({
              data: {
                ent_id: this.ent_id,
                form_id: data.flow_id,
              },
            }).then((rzs) => {
              if (rzs.code == 200) {
                const biaoyi_on_zhi = JSON.parse(JSON.stringify(rzs.body.data));
                // 控件信息
                let three = "";
                if (date.flow_set != null) {
                  three = JSON.parse(date.flow_set);
                }
                let three_zhi = "";
                if (three != null) {
                  three_zhi = three;
                  if (three_zhi.nodeConfig != undefined) {
                    this.$bendi_cunfang_cunchu(
                      "mlbb_liucheng_shezhi",
                      three_zhi.nodeConfig,
                      "暂时"
                    );
                    console.log(three_zhi.nodeConfig);
                  }
                }
              }
            });
          }
        });
      } else {
        query_flow_info_all({
          data: {
            ent_id: this.ent_id,
            flow_id: data.flow_id,
          },
        }).then((res) => {
          if (res.code == 200) {
            query_manager_of_form({
              data: {
                ent_id: this.ent_id,
                form_id: data.flow_id,
              },
            }).then((rzs) => {
              if (rzs.code == 200) {
                const biaoyi_on_zhi = JSON.parse(JSON.stringify(rzs.body.data));
                let three = "";
                if (res.body.action_str != null) {
                  three = JSON.parse(JSON.stringify(res.body.action_str));
                }
                let three_zhi = "";
                if (three != null) {
                  three_zhi = JSON.parse(three.action_str);
                  if (three_zhi.nodeConfig != undefined) {
                    this.$bendi_cunfang_cunchu(
                      "mlbb_liucheng_shezhi",
                      three_zhi.nodeConfig,
                      "暂时"
                    );
                    console.log(three_zhi);
                  }
                }
              }
            });
          }
        });
      }
    },
    async moveList() {
      try {
        const param = {
          ent_id: this.ent_id,
          flow_id: this.YDdata.flow_id,
          flow_group_id: this.activeindex,
          orig_group_id: this.YDdata.orig_group_id,
        };
        console.log(param, "kxb");
        const data = await update_group_of_flow_form({ data: param });
        if (data.code === "200") {
          this.dialogYD = false;
          this.listname = "";
          this.getlist();
          this.$message.success("操作成功");
        } else {
          this.$message.warning("操作失败");
        }
      } catch (e) {
        console.log(e);
      }
    },
    start(val, next) {
      const { flow_group_name } = val;
      const { flow_name, flow_id, flow_group_id } = next;
      this.YDdata.title = flow_name;
      this.YDdata.name = flow_group_name;
      this.YDdata.flow_id = flow_id;
      this.YDdata.orig_group_id = flow_group_id;
      this.dialogYD = true;
    },
    async getlist() {
      const data = await query_flow_group_info_list({
        data: { ent_id: this.ent_id },
      });

      this.beifenData = data;
      this.listData = data.body.data;
      console.log(this.listData, "---");
      this.listData1 = data.body.data.map((item) => {
        return {
          flow_group_name: item.flow_group_name,
          flow_group_id: item.flow_group_id,
        };
      });
    },
    //列表排序
    move(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      const param = {
        ent_id: this.ent_id,
        list: [],
      };
      for (let i = 0; i < arr.length; i++) {
        const obj = {};
        arr[i].sort = i + 1;
        obj.flow_group_id = arr[i].flow_group_id;
        obj.sort = i + 1;
        param.list.push(obj);
      }
      update_flow_group_sort({ data: param })
        .then((res) => {
          if (res.code === "200") {
            this.$message.success("调整成功");
          } else {
            this.$message.success("调整失败");
          }
        })
        .catch((res) => {
          this.$message.success("调整失败");
        });
    },
    async savepush() {
      try {
        let data;
        const param = {
          ent_id: this.ent_id,
          user_id: this.user_id,
          role_group_name: this.listname,
          flow_group_id: this.BJmessage.flow_group_id,
          flow_group_name: this.listname,
        };
        !this.listname && this.$message.warning("不能为空");
        if (this.listname && this.title === "新增分组") {
          data = await insert_flow_group_info({ data: param });
        } else {
          data = await update_flow_group_info({ data: param });
        }
        if (data.code === "200") {
          this.dialogFormVisible = false;
          this.listname = "";
          this.getlist();
        }
        if (data.code =='1200'||data.code =='1100') {
          this.$message({
            message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
            type: 'error'
          })
        }
      } catch (e) {
        console.log(e);
      }
    },
    async deletel(val, index) {
      try {
        const param = {
          ent_id: this.ent_id,
          flow_group_ids: val.flow_group_id,
        };
        const data = await delete_flow_group_info({ data: param });
        if (data.code === "200") {
          this.listData.splice(index, 1);
          this.$message.success("删除成功");
        } else if (data.code === "10900") {
          this.$message.warning("已有表单存在不能删除分组");
        }
      } catch (e) {
        console.log(e);
      }
    },
    //停用
    movestop(val) {
      const param = {
        ent_id: this.ent_id,
        flow_id: val.flow_id,
      };
      form_active_stop({ data: param }).then((res) => {
        if (res.code === "200") {
          this.$message.success("停用成功");
          this.getlist();
        }
      });
    },
    moveitem(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      const param = {
        ent_id: this.ent_id,
        list: [],
      };
      for (let i = 0; i < arr.length; i++) {
        const obj = {};
        arr[i].sort = i + 1;
        obj.flow_id = arr[i].flow_id;
        obj.sort = i + 1;
        param.list.push(obj);
      }
      update_flow_info_sort({ data: param })
        .then((res) => {
          if (res.code === "200") {
            this.$message.success("移动成功");
          } else {
            this.$message.warning("移动失败");
          }
        })
        .catch((res) => {
          this.$message.warning("移动失败");
        });
    },
    randomCoding() {
      //创建26个字母数组
      var arr = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        0,
      ];
      var idvalue = "";
      var n = 10; //这个值可以改变，对应的生成多少个字母，根据自己需求所改
      for (var i = 0; i < n; i++) {
        idvalue += arr[Math.floor(Math.random() * 26)];
      }
      return (this.suijishu = idvalue);
    },
    // 跳转到审批设置页面
    gotolink(items) {
      console.log(items);
      var that = this;
      this.randomCoding();
      // this.xinjianTypeBtn(items.flow_name)

      if (this.xinjianTypeBtn == "xinjian") {
        const liucheng_list = {
          nodeConfig: {
            childNode: null,
            conditionList: [],
            conditionNodes: [],
            nodeName: "发起人",
            nodeUserList: [],
            type: "0",
            id: this.randomCoding(),
          },
        };
        sessionStorage.setItem(
          "mlbb_liucheng_shezhi",
          JSON.stringify(liucheng_list)
        );
        sessionStorage.removeItem("xinjianType");
        sessionStorage.removeItem("mlbb_one_shezhi");
        sessionStorage.removeItem("mlbb_four_shezhi");
        sessionStorage.removeItem("mlbb_biaodan_sheji");
        sessionStorage.removeItem("mlbb_shenpi_zhengyi");
        sessionStorage.removeItem("mlbb_liucheng_shezhi");
        sessionStorage.removeItem("mlbb_liucheng_shezhi_list");
        sessionStorage.removeItem("mlbb_liucheng_shezhi_tableId");
        sessionStorage.removeItem("mlbb_liucheng_shezhi_flowPermission");

        sessionStorage.setItem("mlbb_biaodan_sheji", "[]");

        var datas = {
          ent_id: this.ent_id,
          user_id: this.user_id,
          flow_id: this.suijishu,
          flow_name: "",
          flow_desc: "",
          flow_group_id: null,
          manager: [],
          distinct_flag: null,
          print_flag: "1",
          appr_must_flag: "0",
          appr_auto: "0",
          appr_tips: "",
          form_set: "[]",
          flow_set: "",
          logo_url: "https://www.mlcbr.com/images/logo/tb1.png",
          flag_rw: "0",
          rw_value: "",
          rw_value_type: null,
        };
        const data = JSON.stringify(datas);
        sessionStorage.setItem("xinjianTypeBtn", "xinjian");
        sessionStorage.setItem("xinjianType", data);
        // window.location.href = "/Approval"; //  跳转链接
        that.$router.push("/Approval");
      }
      if (this.xinjianTypeBtn != "xinjian") {
        //编辑

        this.formTypeFun(items.flow_id);
        sessionStorage.setItem("xinjianTypeBtn", "bianji");
        // console.log(this.$ent_id(),items.flow_id,sessionStorage.getItem('formTypeBtn'))
        let aaa = sessionStorage.getItem("formTypeBtn");
        console.log(aaa);
        if (aaa === "1") {
          console.log(aaa);
          // 自定义表单
          query_cust_form_detail({
            data: {
              ent_id: this.ent_id,
              flow_id: items.flow_id,
            },
          }).then((res) => {
            console.log(res);
            if (res.code == 200) {
              const form_set = res.body.data.form_set;
              const flow_set = res.body.data.flow_set;
              sessionStorage.setItem("mlbb_liucheng_shezhi", flow_set);
              sessionStorage.setItem("mlbb_biaodan_sheji", form_set);

              sessionStorage.setItem(
                "xinjianType",
                JSON.stringify(res.body.data)
              );
              // window.location.href = '/Approval' //  跳转链接
              that.$router.push("/Approval");
            }
          });
        }
        if (aaa === "2") {
          console.log(aaa);
          // 公共表单
          query_flow_info_all({
            data: {
              ent_id: this.ent_id,
              flow_id: items.flow_id,
            },
          }).then((res) => {
            if (res.code == 200) {
              const form_set = JSON.stringify(res.body.form_str);
              const flow_set = res.body.action_str ? res.body.action_str.action_str : "{}";
              sessionStorage.setItem("mlbb_liucheng_shezhi", flow_set);

              console.log(res, flow_set);
              sessionStorage.setItem("mlbb_biaodan_sheji", form_set);
              sessionStorage.setItem(
                "xinjianType",
                JSON.stringify(res.body.flow)
              );

              // window.location.href = "/Approval"; //  跳转链接
              that.$router.push("/Approval");
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$BackColor-main: #fff;
$BackColor-hover: darken($BackColor-main, 10%);
.title {
  display: inline-flex;
  flex-flow: row nowrap;
  font-size: 20px;
  height: 60px;
  vertical-align: middle;
  align-items: center;
  > p {
    margin-left: 0px;
  }
  > * {
    margin-left: 10px;
  }
}
.shenpi{
  background: #fff;
   height: 800px;
  .title{
    margin-left: 20px;
  }
}
.shenpi > ul {
  > li {
    width: 100%;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
      background: linear-gradient(180deg, #FC4353, #FF5E41);
    border-bottom: none;
    min-height: 48px;
    position: relative;
    color: #fff;
    font-weight: 500;
    margin-bottom: 20px;
    > .c-icon {
      font-size: 20em;
    }
    .tram {
      transform: rotate(90deg);
      transition: transform 0.5s;
    }
    > p:nth-child(2) {
      position: absolute;
      right: 4%;
    }
    > p {
      font-size: 14px;
      text-align: center;
    }
    > span > a {
      width: 60px;
      font-size: 14px;
      display: inline-flex;
      color: #fff;
      text-decoration: none;
    }
  }
  > .content {
    width: 100%;
    display: inline-flex;
    height: 0;
    overflow: hidden;
    flex-flow: column nowrap;
    align-items: center;
    font-size: 14px;
    > li {
      width: 100%;
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 10px;
      box-sizing: border-box;
      border-bottom: none;
      height: 40px;
      > p:nth-child(2) {
        position: absolute;
        left: 44%;
      }
      > span > a {
        width: 60px;
        display: inline-flex;
        color: #000;
        text-decoration: none;
      }
      &:hover {
        background: rgba($color: #fc4353, $alpha: 0.2);
        transition: 0.3s;
      }
    }
    &.active {
      height: auto;
      transition: height 1s;
    }
  }
}
::v-deep .dialog1 {
  width: 400px;
}
.firstButton {
  width: 160px;
}
.dialog {
  background: #fff;
  > ul {
    padding-left: 10px;
    padding-right: 10px;
    > li {
      padding-left: 8px;
      padding-right: 8px;
      line-height: 40px;
      border-bottom: 1px solid #ccc;
      display: flex;
      justify-content: space-between;
      > p {
        &:last-child {
          background: #ccc;
          border-radius: 4px;
          padding: 0px 10px 0px 10px;
        }
      }
      &.active {
        background: rgb(240, 240, 240);
      }
    }
  }
}
</style>
